import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class FirecacheService {

  baseUrl = 'https://us-central1-techpad-17393.cloudfunctions.net';

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllCategories(serverSideRequired = true) {
    return Observable.create(observer => {
      let allCategories = localStorage.getItem('allCategories');
      if (allCategories) {
        observer.next(JSON.parse(allCategories));
      } else {
        serverSideRequired = true;
      }
      if (serverSideRequired) {
        this.httpClient.get(
          this.baseUrl + '/getAllCategories'
        ).subscribe(response => {
          localStorage.setItem('allCategories', JSON.stringify(response));
          observer.next(response);
        })
      }
    })
  }

  getAllPosts(serverSideRequired = true) {
    return Observable.create(observer => {
      let allPosts = localStorage.getItem('allPosts');
      if (allPosts) {
        observer.next(JSON.parse(allPosts))
      } else {
        serverSideRequired = true;
      }
      if (serverSideRequired) {
        this.httpClient.get(
          this.baseUrl + '/getAllPosts'
        ).subscribe(response => {
          localStorage.setItem('allPosts', JSON.stringify(response));
          observer.next(response);
        })
      }
    })
  }

  getPostData(postId) {
    return Observable.create(observer => {
      let allPosts = localStorage.getItem('allPosts');
      if (allPosts) {
        const posts = JSON.parse(allPosts);
        const post = posts.find(post => post.id === postId);
        if (post) {
          observer.next(post);
        }
      }
      this.httpClient.get(
        this.baseUrl + '/getAllPosts'
      ).subscribe((response: []) => {
        localStorage.setItem('allPosts', JSON.stringify(response));
        const posts = response;
        const post = posts.find(post => post['id'] === postId);
        if (post) {
          observer.next(post);
        }
      })
    })
  }

}