import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFormater'
})
export class TimeFormaterPipe implements PipeTransform {

  transform(value: any, format = 'D MMM YY'): any {
    return moment(value).format(format);
  }

}
