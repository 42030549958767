import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrafficManagerService {

  baseUrl = 'https://us-central1-techpad-17393.cloudfunctions.net';

  constructor(
    private httpClient: HttpClient
  ) { }

  updatePostView(postId) {
    return new Promise(resolve => {
      let updateRequired = false;
      const lastUpdated = localStorage.getItem(postId);
      if (lastUpdated) {
        if ((+new Date()) - Number(lastUpdated) > 3600000 ) {
          updateRequired = true;
        }
      } else {
        updateRequired = true;
      }
      if (updateRequired) {
        this.httpClient.get(
          this.baseUrl + '/increasePostViewCount?postId=' + postId
        ).subscribe(response => {
          localStorage.setItem(postId, JSON.stringify((+new Date())));
        })
      }
    })
  }

}
