import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FirecacheService } from '../firecache.service';
import { TrafficManagerService } from '../traffic-manager.service';
import {Title, Meta} from "@angular/platform-browser";
import { GistComponent } from '@sgbj/angular-gist';
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  @ViewChild('gist') gist;
  postData: any;
  formattedGistUrl = '';
  allPosts = [];
  contentLoaded = false;
  showRealtedPosts = false;

  constructor(
    private router: Router,
    private firecache: FirecacheService,
    private titleService:Title,
    private trafficManagerService: TrafficManagerService,
    private meta: Meta,
  ) { }

  ngOnInit() {
    window.scroll(0,0);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.loadPostData();
    this.loadPopularPosts();
    this.trafficManagerService.updatePostView(this.getPostIdFromUrl());
  }

  loadPopularPosts() {
    this.firecache.getAllPosts()
      .subscribe((allPosts: []) => {
        const postId = this.getPostIdFromUrl();
        // Exclude current post
        const filteredPosts = []
        allPosts.forEach(post => {
          if (post['id'] !== postId)
          filteredPosts.push(post);
        })
        this.allPosts = this.sort(filteredPosts).slice(0, 4);
        setTimeout(() => {
          this.showRealtedPosts = true;
        }, 0);
      })
  }

  sort(posts, sortBy = 'views') {
    return posts.sort((a,b) => {
      return b.data[sortBy] - a.data[sortBy];
    })
  }

  openPost(post) {
    this.router.navigateByUrl('post/'+post.id);
  }

  loadPostData() {
    const postId = this.getPostIdFromUrl();
    this.firecache.getPostData(postId)
      .subscribe(postData => { 
        this.postData = postData;
        this.setPostMetaData(postData.data);
        const linkParts = postData.data.gistLink.split('/');
        this.formattedGistUrl = postData.data.gistLink;
        setTimeout(() => {
          this.contentLoaded = true;
        }, 1600);
      })
  }

  viewProfile() {
    window.location.replace('https://debojyoti.dev');
  }

  setPostMetaData(postData) {
    this.titleService.setTitle(postData.title);
      this.meta.updateTag({name:'og:image', content: postData.imageLink? postData.imageLink : 'https://res.cloudinary.com/dqzurhnfk/image/upload/v1562494133/default_preview_image_rdmjhx.png'})
      this.meta.updateTag( {name:'og:title', content: postData.title})
      this.meta.updateTag( {name:'og:description', content: postData.summary? postData.summary: ''})
      this.meta.updateTag( {name:'og:url', content: 'techpad.debojyoti.dev'})
  }

  gotoHome() {
    this.router.navigateByUrl('home');
  }

  getPostIdFromUrl() {
    return this.router.url.split('/')[2];
  }
}
