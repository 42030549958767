import { Component, OnInit, ViewChild } from '@angular/core';
import { FirecacheService } from '../firecache.service';
import { flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  placeHolderTexts = [
    "Search posts",
    "Try 'firebase crud'"
  ];
  placeHolderCurrentText = this.placeHolderTexts[0];
  placeholderCurrentIndex = 1;

  allCategories: any;
  allPosts: any;
  categoriesArray = [];
  searchedPosts = [];
  searchInputValue: string;
  lastFilteredByCategory = '';
  contentLoaded = false;
  visiblePosts = [];
  displayCount = 9;     //  Minimum cards to display

  constructor(
    private firecacheService: FirecacheService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
  ) { }

  ngOnInit() {
    this.setPostMetaData();
    window.scroll(0,0);
    this.initLoad();
    this.startPlaceHolderAnimation();
  }

  setPostMetaData() {
    this.titleService.setTitle('Techpad | Home');
      this.meta.updateTag({name:'og:image', content: 'https://res.cloudinary.com/dqzurhnfk/image/upload/v1562494133/default_preview_image_rdmjhx.png'})
      this.meta.updateTag( {name:'og:title', content: 'Techpad | Home'})
      this.meta.updateTag( {name:'og:description', content: 'Blog Posts | Common solutions'})
      this.meta.updateTag( {name:'og:url', content: 'techpad.debojyoti.dev'})
  }

  startPlaceHolderAnimation() {
    setInterval(() => {
      if (this.placeholderCurrentIndex === this.placeHolderTexts.length) {
        this.placeholderCurrentIndex = 0;
      }
      this.placeHolderCurrentText = this.placeHolderTexts[this.placeholderCurrentIndex++];
    }, 5000);
  }

  viewProfile() {
    window.location.replace('https://debojyoti.dev');
  }

  initLoad() {
    this.firecacheService.getAllCategories()
      .pipe(
        flatMap((allCategories: []) => {
          this.categoriesArray = allCategories;
          this.categoriesArray = this.sort(this.categoriesArray, "name");
          console.log(this.categoriesArray);
          this.allCategories = {};
          // Convert indexed array to associative (object)
          allCategories.forEach(category => {
            this.allCategories[category['id']] = category['data']['name'];
          })
          return this.firecacheService.getAllPosts();
        })
      ).subscribe(allPosts => {
        this.allPosts = allPosts;
        this.searchedPosts = this.sort(this.allPosts);
        this.visiblePosts = this.searchedPosts.slice(0, this.displayCount);
        this.contentLoaded = true;
      })
  }

  filterBy(e = this.lastFilteredByCategory) {
    this.searchedPosts = this.allPosts;
    this.searchPosts();
    const selectedCategoryId = e;
    this.lastFilteredByCategory = selectedCategoryId;
    if (selectedCategoryId) {
      const filteredPosts = this.searchedPosts.filter(post => {
        return post.data.categories.indexOf(selectedCategoryId) > -1;
      })
      this.searchedPosts = this.sort(filteredPosts);
    } else {
      this.searchedPosts = this.sort(this.searchedPosts)
    }
    this.visiblePosts = this.searchedPosts.slice(0, this.displayCount);
  }

  sort(posts, sortBy = 'added_on') {
    return posts.sort((a,b) => {
      return b.data[sortBy] - a.data[sortBy];
    })
  }

  openPost(post) {
    this.router.navigateByUrl('post/'+post.id);
  }

  searchPosts() {
    if (this.searchInputValue) {
      this.searchedPosts = this.allPosts.filter(post => {
        const postTitle = post.data.title.toLowerCase().trim();
        const postSummary = post.data.summary.toLowerCase().trim();
        const searchedValue = this.searchInputValue.toLowerCase().trim();
        return (postTitle.indexOf(searchedValue) > -1)  || (postSummary.indexOf(searchedValue) > -1);
      });
    } else {
      this.searchedPosts = this.allPosts;
    }
  }

  showMore() {
    this.displayCount += 6;
    this.filterBy();
  }

}
